import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from 'react-bootstrap/InputGroup'
import Button from "react-bootstrap/Button";
import {callApi, searchProduct} from "../utils/api";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import ListGroup from 'react-bootstrap/ListGroup'

/**
 * Show a modal search for products by name.
 * props:
 * - show: boolean when it is show
 * - onHide: event for closing the modal
 * - onSelected: event when selecting a product
 *
 * @example  <ProductSearch key="new-product-modal" show={this.state.newProduct} onHide={()=> this.setState({newProduct:false})} onSelected={this.addProduct.bind(this)} />
 */
export default function ProductSearch(props){
    const [search, setSearch] = useState('');
    const [spinner, setSpinner] = useState(false)
    const [results, setResults] = useState(null)
    const [error, setError] = useState('');
    const [valid, setValid] = useState(true);
    const [selected, setSelected] = useState(null);
    const [notFound, setNotFound] = useState(null);

    const responseError = (e) => {
        setError(e.toString())
        setSpinner(false)
        setValid(true)
        setSearch('')
        setNotFound(null)
    }

    const searchProducts = () => {
        if (search !== '') {
            setSpinner(true)
            searchProduct(search,
                (r) => {
                    setResults(r)
                    setSpinner(false)
                    setValid(true)
                    setSearch('')
                    notFoundMessage()
                },
                (e) => responseError(e))
        }else {
            setValid(false)
        }
    }

    const clearState = () => {
        setSearch('')
        setSpinner(false)
        setValid(true)
        setResults(null)
        setSelected(null)
        setNotFound(null)
    }

    const close = () => {
        props.onHide()
        clearState()
    }

    const onSelected = () => {
        props.onSelected(selected)
        props.onHide()
        clearState()
    }

    const notFoundMessage = () => {
        if (results!=null && results.length === 0){
            return setNotFound("Not found any product.");
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Search Product Nodes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error !== '' &&
                    <Alert variant="danger" onClose={() => setError('')} dismissible>
                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                        <p>{error}</p>
                    </Alert>
                }
                <InputGroup className="mb-3">
                    <FormControl isInvalid={!valid} required type="text" placeholder="Search" className="mr-sm-2" onChange={(event) => setSearch(event.target.value) } />
                    <InputGroup.Append><Button variant="info" onClick={searchProducts}>Search</Button></InputGroup.Append>
                </InputGroup>
                { notFound }
                <ListGroup>

                    {results!=null && results.map(n => selected != null && selected.productId === n.productId
                        ? <ListGroup.Item key={n.productId} action active>{n.name}{n.confidential ? <span className="ml-1"><i className="fa-solid fa-lock"></i></span> : null}</ListGroup.Item>
                        : <ListGroup.Item key={n.productId} action onClick={()=>setSelected(n)}>{n.name}{n.confidential ? <span className="ml-1"><i className="fa-solid fa-lock"></i></span> : null}</ListGroup.Item>
                    )}
                </ListGroup>
                {spinner &&
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={selected === null} onClick={onSelected}>Select</Button> <Button onClick={close}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}