export const orderNodes = (node, offset, nodes) => {
    let pos = nodes.indexOf(node)+offset
    if (pos < 0 || pos >= nodes.length){
        // return same nodes
        return nodes
    }
    let filtered = Object.assign([], nodes.filter(i => i.nodeId !== node.nodeId))
    let prev = filtered.slice(0, Math.max(0,pos))
    let post = filtered.slice(pos, Math.max(pos, filtered.length))
    return prev.concat([node].concat(post))
}