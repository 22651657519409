import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

export default function NewSingleValue(props){
    const [text, setText] = useState('');

    const create = () => {
        props.onSuccess(text);
        props.onHide();
        setText('')
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">Name</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl value={text} aria-label="Default"  aria-describedby="inputGroup-sizing-default" placeholder="Name" onChange={(event) => setText(event.target.value) } />
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
                <Button variant="primary" mt={2} disabled={text.length < 1} onClick={create}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}