import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";

const client = window.location.host === ('prd-ux-navigation-webapp-service.azurewebsites.net') || window.location.host === ('uxn-service.arm.com') ? 'ac27c368-c766-4811-aeed-3a3242320831' : '481591c9-70c1-4273-bc71-efe129dc92b9';
// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
export const authProvider = new MsalAuthProvider(
    {
        auth: {
            authority: "https://login.microsoftonline.com/f34e5979-57d9-4aaa-ad4d-b122a662184d", // https://login.microsoftonline.com/common
            clientId: client,
            postLogoutRedirectUri: location.protocol + '//' + location.host + location.pathname,
            redirectUri: location.protocol + '//' + location.host + location.pathname,
            validateAuthority: true,

            // After being redirected to the "redirectUri" page, should user
            // be redirected back to the Url where their login originated from?
            navigateToLoginRequestUrl: true
        },
        // Enable logging of MSAL events for easier troubleshooting.
        // This should be disabled in production builds.
        system: {
            logger: new Logger(
                (logLevel, message, containsPii) => {
                    console.log("[MSAL]", message);
                },
                {
                    level: LogLevel.Verbose,
                    piiLoggingEnabled: false
                }
            )
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: true
        }
    },
    {
        scopes: [client+'/.default']
    },
    {
        loginType: LoginType.Redirect,
        // When a token is refreshed it will be done by loading a page in an iframe.
        // Rather than reloading the same page, we can point to an empty html file which will prevent
        // site resources from being loaded twice.
        tokenRefreshUri: window.location.origin + "/auth.html"
    }
);