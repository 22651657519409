import React from "react";
import {getNode, getProductNode, updateProductNode} from "../utils/api";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import ProductSearch from "../products/product-search";
import AlertError from "../utils/alert-error";
import ConfirmModal from "../utils/confirm";

export default class NavigationProduct extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            nodeId: props.nodeId,
            products: null,
            error: '',
            spinner: true,
            newProduct: false,
            productSelected: null
        }
    }

    componentDidMount(){
        this.fetchProducts(this.state.nodeId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.nodeId !== prevProps.nodeId) {
            this.fetchProducts(this.props.nodeId);
        }
    }

    deleteProduct = () => {
        this.setState({spinner:true})
        let productsUpdated = this.state.products.filter(p => p.productId !== this.state.productSelected)
        updateProductNode(this.props.nodeId, productsUpdated.map(p => p.productId),
            (p) => this.setState({products: p, spinner:false, productSelected:null}),
            (e) => this.setState({error:e, spinner:false, productSelected:null}))
    }

    addProduct = (product) => {
        this.setState({spinner:true, newProduct: false})
        updateProductNode(this.props.nodeId, [...this.state.products.map(p => p.productId), product.productId],
            (p) => this.setState({products: p, spinner:false}),
            (e) => this.setState({error:e, spinner:false}))
    }

    fetchProducts = (id) => {
        this.setState({spinner:true})
        getProductNode(id,
            (products) => this.setState({spinner: false, products: products}),
            (e) => this.setState({error: e, spinner: false})
        )
    }

    render(){
        return [
            <div key="product-information">
                {this.state.error !== '' && <AlertError key="error-message" handler={() => this.setState({error: ''})} error={this.state.error} />}
                {this.state.spinner &&
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                }
                <Row>
                    <Col sm={4}><h5>Product List</h5></Col>
                    <Col sm={4} ><Button variant="success" onClick={() => this.setState({newProduct: true})}>Add Product</Button></Col>
                </Row>
                {this.state.products !== null ?
                    <Row className="mt-2">
                        <Col>
                        {   this.state.products.length > 0 ?
                                this.state.products.map(p =>
                                        <Alert key={p.productId} className="alert-auto" variant="info" onClose={() => this.setState({confirmDeleteProduct: true, productSelected:p.productId})}  dismissible>
                                            {p.name}
                                            {p.confidential ? <span className="ml-1"><i className="fa-solid fa-lock"></i></span> : null}
                                        </Alert>)
                            : <p>There are no products related to this navigation node</p>
                        }
                        </Col>
                    </Row>
                : null}
            </div>,
            <ProductSearch key="new-product-modal" show={this.state.newProduct} onHide={()=> this.setState({newProduct:false})} onSelected={this.addProduct.bind(this)} />,
            <ConfirmModal key="confirmation-delete-product" show={this.state.confirmDeleteProduct} onHide={() => this.setState({confirmDeleteProduct: false})} onConfirmation={this.deleteProduct.bind(this)} />
        ]

    }
}