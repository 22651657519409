import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import React from "react";
import LabelList from "../labels/label-list";
import NavigationHierarchy from "../navigation/navigation-hierarchy";
import ConfirmModal from "../utils/confirm";
import {updateHierarchy, updateNode} from "../utils/api";

export default class Hierarchy extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            hierarchy: props.hierarchy != null ? props.hierarchy : null,
            confirmDeleteHierarchy: false
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.hierarchy !== this.props.hierarchy){
            this.setState({hierarchy: this.props.hierarchy})
        }
    }

    deleteHierarchy = () => {
        // called by hierarchy-list
        this.props.onDelete(this.state.hierarchy.hierarchyId)
        this.setState({confirmDeleteHierarchy: false})
    }


    updateHierarchy = (labels) => {
        console.log("updating Hierarchy")
        // keep track of old hierarchy
        let hierarchy = Object.assign({}, this.state.hierarchy)
        // update the labels with the new one.
        this.state.hierarchy.labels = labels

        updateHierarchy(this.state.hierarchy,
            (h) => this.setState({hierarhcy: h}),
            (e) => {
                this.setState({hierarchy: hierarchy})
                this.props.onError(e)
        })
    }

    render(){
        if ( this.state.hierarchy != null) {
            return [<Card key="hierarchy-render" className="mt-1" >
                    <Card.Header>Hierarchy</Card.Header>
                    <Card.Body>
                        <Card.Title>{this.state.hierarchy.hierarchyId} </Card.Title>
                        <Card.Body >
                            <LabelList labels={this.state.hierarchy.labels} onUpdate={this.updateHierarchy.bind(this)}/>
                            <Button variant="danger" onClick={() => this.setState({confirmDeleteHierarchy:true})}>Delete Hierarchy</Button>
                        </Card.Body>
                    </Card.Body>
                </Card>,
                <NavigationHierarchy key="navigation-nodes" hierarchy={this.state.hierarchy} />,
                <ConfirmModal key="confirmation-delete-hierarchy" show={this.state.confirmDeleteHierarchy} onHide={() => this.setState({confirmDeleteHierarchy: false})} onConfirmation={this.deleteHierarchy.bind(this)} />
            ]
        }
        return null

    }

}