import React from "react";
import Button from "react-bootstrap/Button";
import NavigationSearch from "./navigation-search";
import Navigation from "./navigation";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AlertError from "../utils/alert-error";
import {callApi, createNode} from "../utils/api";
import NewSingleValue from "../utils/new-single-value";

export default class NavigationList extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            search: false,
            node: null,
            error: '',
            newNode: false
        }
    }

    selectedNode = (node) => {
        this.setState({node: node})
    }

    createNode = (name) => {
        let data = {}
        data.labels = [{language: "en", name: name}]
        data.confidential = true
        console.log("about to insert new Node "+ JSON.stringify(data));
        createNode(data,
            (n) => this.setState({node: n}),
            (e) => this.setState({error: e}))
    }

    render() {
        return [ (this.state.error !== '' && <AlertError key="error-message" handler={this.cleanError} error={this.state.error} /> ),
            <Container key="navigation-details">
                <Row>
                    <Col><Button variant="primary" onClick={() => this.setState({search: true})}>Search Navigation Nodes</Button></Col>
                    <Col><Button variant="success" onClick={() => this.setState({newNode:true})}>New Navigation Nodes</Button></Col>
                </Row>
                <Row>
                    <Col>{this.state.node !== null ? <Navigation node={this.state.node} onError={(e) => this.setState({error:e})} deletable={true} path={true}/> : null }</Col>
                </Row>
            </Container>,
            <NavigationSearch key="navigation-search" show={this.state.search} onHide={() =>  this.setState({search: false})} onSelected={this.selectedNode.bind(this)} />,
            <NewSingleValue key="node-new" title="New Navigation Node" show={this.state.newNode} onHide={() =>  this.setState({newNode: false})} onSuccess={this.createNode.bind(this)}/>
            ];
    }
}