import React from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import LabelList from "../labels/label-list";
import {deleteNode, updateNode, toggleNodeConfidentiality} from "../utils/api";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ConfirmModal from "../utils/confirm";
import AlertError from "../utils/alert-error";
import HierarchyPath from "./hierarchy-path";
import NavigationProduct from "./navigation-product";
import ConfidentialToggle from "../confidential/confidential-toggle";

export default class Navigation extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            node : props.node,
            confirmDeleteNode:false,
            productSelected: null,
            error: '',
            deletable : props.deletable || false
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.node !== this.props.node){
            this.setState({node: this.props.node})
        }
    }

    updateNavigationNode = (node) => {
        let oldNode = Object.assign({}, this.state.node)
        updateNode(node,
            (n) => this.setState({ node: n }),
            (e) => {
                this.setState({ node: oldNode })
                this.props.onError(e)
            })
    }

    updateLabel = (labels) => {
        let node = Object.assign({}, this.state.node)
        node.labels = labels
        this.updateNavigationNode(node);
    }


    updateConfidential = (confidential) => {
        let node = Object.assign({}, this.state.node);
        node.confidential = confidential;
        toggleNodeConfidentiality(node,
            (n) => this.setState({ node: n }),
            (e) => {
                this.props.onError(e)
            })
    }

    deleteNode = () => {
        deleteNode(this.state.node.nodeId,
            () => this.setState({ node: null }),
            (e) => this.setState({error: e}))
    }

    render(){
        return [
            (this.state.error !== '' && <AlertError key="error-message" handler={() => this.setState({error: ''})} error={this.state.error} />),
            (this.state.node != null
                ? <Card key="node-render" className="mt-1" >
                        <Card.Header>
                            <Container>
                                <Row>
                                    <Col sm={8}><h5>Navigation Node</h5></Col>
                                    <Col sm={4}>{ this.state.deletable ? <Button variant="danger" onClick={() => this.setState({confirmDeleteNode: true})}>Delete Navigation Node</Button> : null}</Col>
                                </Row>
                            </Container>
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>{this.state.node.nodeId}</Card.Title>
                            <Card.Body >
                                <Container>
                                    <Row key="label-list-header">
                                        <Col><h5>Label List</h5></Col>
                                    </Row>
                                    <Row key="label-list">
                                        <Col><LabelList labels={this.state.node.labels} onUpdate={this.updateLabel.bind(this)}/></Col>
                                    </Row>
                                    <Row key="confidential-toggle">
                                        <Col><ConfidentialToggle confidential={this.state.node.confidential} id={this.state.node.nodeId} onUpdate={this.updateConfidential.bind(this)} /></Col>
                                    </Row>
                                    {
                                        this.props.path
                                            ? [
                                                <Row key="product-list">
                                                    <Col><NavigationProduct nodeId={this.state.node.nodeId} /></Col>
                                                </Row>
                                            ]
                                            : null
                                    }
                                    {
                                        this.props.path
                                            ? [
                                                <Row key="hierarchy-list-header">
                                                    <Col><h5>Hierarchies</h5></Col>
                                                </Row>,
                                                <Row key="hierarchy-list">
                                                    <Col><HierarchyPath paths={this.state.node.hierarchyPath || []}/></Col>
                                                </Row>
                                            ]
                                            : null
                                    }
                                </Container>
                            </Card.Body>
                        </Card.Body>
                    </Card>
                : null ),
            <ConfirmModal key="confirmation-delete-node" show={this.state.confirmDeleteNode} onHide={() => this.setState({confirmDeleteNode: false})} onConfirmation={this.deleteNode.bind(this)} />
        ];
    }
}