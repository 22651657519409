import React from 'react'
import HierarchyList from "./hierarchies/hierarchy-list"
import Navbar  from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavigationList from "./navigation/navigation-list";
import ProductList from "./products/product-list";
import {Route, Switch, Link }from 'react-router-dom';

class App extends React.Component {
    render(){
        return (
            <div id="app" className="p-1 m-1">
                <Navbar bg="dark" variant="dark" fixed="top">
                    <Navbar.Brand as={Link} to="/app">UX Navigation App</Navbar.Brand>
                    <Nav variant="pills" defaultActiveKey="hierarchies" className="mr-auto">
                        <Nav.Item><Nav.Link as={Link} eventKey="hierarchies" to="/app">Hierarchies</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link as={Link} eventKey="nodes" to="/app/nodes">Navigation nodes</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link as={Link} eventKey="products" to="/app/products">Products</Nav.Link></Nav.Item>
                    </Nav>
                    <Nav.Link href="https://confluence.arm.com/display/ADC/Front-end+Feedback">Feedback</Nav.Link>
                    <Navbar.Text>
                        Signed in as: {this.props.name}
                    </Navbar.Text>
                </Navbar>
                <Switch>
                    <Route exact path="/app" component={HierarchyList} />
                    <Route exact path="/app/nodes" component={NavigationList} />
                    <Route exact path="/app/products" component={ProductList} />
                </Switch>
            </div>
        )
    }
}

export default App;