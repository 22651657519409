import Button from "react-bootstrap/Button";
import React from "react";
import FormControl from "react-bootstrap/FormControl";
import ConfirmModal from "../utils/confirm";

export default class Label extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            label: props.label,
            edit: false,
            text: '',
            confirmChange: false,
            confirmDelete: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.label !== this.props.label){
            this.setState({label: this.props.label})
        }
    }

    confirmSaveLabel = () => {
        let label = Object.assign({}, this.state.label)
        this.props.onUpdate(label, this.state.text)
        this.clean()
    }

    confirmDeleteLabel = () => {
        this.props.onDelete(this.state.label);
        this.clean()
    }

    clean = () => {
        this.setState({
            edit: false,
            text: '',
            confirmChange: false,
            confirmDelete : false
        })
    }

    render () {
        const label = this.state.label
        return [
        <tr key="label-info">
            <td >{label.language}</td>
            <td>{ this.state.edit ? <FormControl defaultValue={label.name} onChange={(e) => this.setState({text:e.target.value})} /> : label.name}</td>
            <td>
                { this.state.edit
                ? [<Button key="save" variant="success" onClick={() => this.setState({confirmChange: true})}>Save</Button>, " ", <Button key="cancel" variant="warning" onClick={this.clean}>Cancel</Button>]
                : [
                    <Button key="edit" variant="info" onClick={() => this.setState({edit:true})}>Edit</Button>,
                    " ",
                    (label.language.toLowerCase() !== "en" ? <Button key="delete" variant="danger" onClick={() => this.setState({confirmDelete: true})}>Delete</Button> : null)
                    ]
                }
            </td>
        </tr>,
        <ConfirmModal key="confirmation-change-label" show={this.state.confirmChange} onHide={this.clean} onConfirmation={this.confirmSaveLabel.bind(this)} />,
        <ConfirmModal key="confirmation-delete-label" show={this.state.confirmDelete} onHide={this.clean} onConfirmation={this.confirmDeleteLabel.bind(this)} />
        ]
    }

}