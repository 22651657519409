import Card from "react-bootstrap/Card";
import React from "react";
import {Button} from "react-bootstrap";
import NavigationPath from "./navigation-path";
import Accordion from 'react-bootstrap/Accordion'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {addPath, callApi, deleteNode, deletePath, reorderChildren, updateNode} from "../utils/api";
import NavigationSearch from "./navigation-search";
import ConfirmModal from "../utils/confirm";
import Navigation from "./navigation";
import {orderNodes} from "../utils/common-functions";
import Spinner from "react-bootstrap/Spinner";

export default class NavigationHierarchyItem extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            node : props.node,
            ancestors : props.ancestors,
            search: false,
            deleteConfirm:false,
            spinner:false
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.node !== this.props.node){
            this.setState({node: this.props.node})
        }
    }

    updateNode = (node) => {
        this.setState({spinner:true})
        let oldNode = this.state.node
        updateNode(node,
            (n) => {
                this.setState({ node: n, deleteConfirm: false, spinner:false })
                this.props.onRefresh()
            },
            (e) => {
                this.setState({node : oldNode, deleteConfirm:false, spinner:false})
                this.props.onError(e)
            })
    }


    deleteNode = () => {
        this.setState({spinner:true})
        let data = {}
        data.ancestors = Object.assign([], this.state.ancestors)
        deletePath(this.state.node.nodeId, data,
            (n) => {
                this.setState({deleteConfirm:false, spinner:false})
                this.props.onRefresh()
            },
            (e) => {
                this.props.onError(e)
                this.setState({spinner:false})
            })
    }

    addNode = (node) => {
        this.setState({spinner:true})
        let data = {}
        data.ancestors =  this.computeAncestors()
        addPath(node.nodeId, data,
            (n) => {
                let updated = this.state.node
                if (!updated.children) updated.children = []
                updated.children.push(node)
                this.setState({node: updated, spinner:false}) // trigger render
                this.props.onRefresh()
            },
            (e) => {
                this.props.onError(e)
                this.setState({spinner:false})
            })
    }

    computeAncestors = () => {
        let ancestors =  Object.assign([], this.state.ancestors)
        ancestors.push(this.state.node.nodeId)
        return ancestors
    }

    reorderChildren = (node, offset) => {
        this.setState({spinner:true})
        let ordered = orderNodes(node, offset, this.state.node.children)
        let data = {}
        data.navigationNodeOrdered = ordered.map(n => n.nodeId)
        reorderChildren(this.state.node.nodeId, data,
            (n) => {
                let node = n
                node.children = ordered
                this.setState({node: node, spinner:false})
            },
            (e) => {
                this.props.onError(e)
                this.setState({spinner:false})
            })
    }

    render(){

        return [
            <Accordion key="navigation-hierarchy-item" defaultActiveKey={0}>
                <Card className="mt-1" >
                    <Card.Header>
                        <Card.Title>{this.state.node.labels.filter(l => l.language.toLowerCase() === 'en')[0].name} <small>{this.state.node.nodeId}</small></Card.Title>
                        <Container>
                            <Row>
                                <Col xs={1}><Accordion.Toggle as={Button} variant="link" eventKey={this.state.node.nodeId}>(+)</Accordion.Toggle></Col>
                                <Col xs={8}>
                                    <Button variant="info" onClick={() => this.props.onReorder(this.state.node, -1)}>Move Up</Button>&nbsp;
                                    <Button variant="info" onClick={() => this.props.onReorder(this.state.node, 1)}>Move Down</Button></Col>
                                <Col><Button variant="danger" onClick={() => this.setState({deleteConfirm: true})}>Delete from tree</Button></Col>
                            </Row>
                        </Container>
                    </Card.Header>
                    <Accordion.Collapse eventKey={this.state.node.nodeId}>
                        <Card.Body>
                            <Container>
                                    <Navigation node={this.state.node} path={false} />
                                    <Row className="mt-4">
                                        <Col><h5>Children : {this.state.node.children != null ? this.state.node.children.length : 0}</h5></Col>
                                        <Col><Button variant="success" onClick={() =>  this.setState({search: true})}>Add Children</Button></Col>
                                        <Col><NavigationPath path={this.state.node.navigationPath} /></Col>
                                    </Row>
                                <Row><Col>
                                    {this.state.spinner &&
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                    }
                                    {this.state.node.children != null && this.state.node.children.map(n =>
                                        <NavigationHierarchyItem key={n.nodeId} node={n} ancestors={this.computeAncestors()} onRefresh={() => this.props.onRefresh() } onReorder={this.reorderChildren.bind(this)} onError={this.props.onError}/>)
                                    }
                                </Col></Row>
                            </Container>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>,
            <NavigationSearch key="navigation-search" show={this.state.search} onHide={() =>  this.setState({search: false})} onSelected={this.addNode.bind(this)} />,
            <ConfirmModal key="confirmation-delete-node" show={this.state.deleteConfirm} onHide={() => this.setState({deleteConfirm: false})} onConfirmation={this.deleteNode.bind(this)} />,
        ]
    }
}