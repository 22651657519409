import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal'
import Button from "react-bootstrap/Button";

export default function ConfirmModal(props) {

    const confirmed = () => {
        props.onConfirmation();
        props.onHide()
    }

    return (
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to do this operation?
                    {props.alertMessage ?
                        <div><strong>{props.alertMessage}</strong></div>
                    : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmed}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
    );
}