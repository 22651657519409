import {authProvider} from '../security/auth-provider';

export const callApi = async (method: 'GET' | 'POST' | 'PUT' | 'DELETE', url: string, data?: any) => {
    const token = await authProvider.getAccessToken();
    return await fetch(`${url}`, {
        method,
        headers: {
            Authorization: 'Bearer ' + token.accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
};

export const updateHierarchy = (hierarchy, success, error) => {
    callApi('PUT', '/hierarchies/'+ hierarchy.hierarchyId, hierarchy)
        .then(res => manageResponse(res, 200))
        .then(n => success(n))
        .catch(e => error(e.message))
}

export const deleteHierarchy = (hierarchyId, success, error) => {
    callApi('DELETE', '/hierarchies/'+ hierarchyId)
        .then(res => manageDeletionResponse(res, 204))
        .then(p => success())
        .catch(e => error(e.message));
}

export const createHierarchy = (node, success, error) => {
    callApi('POST', '/hierarchies', node)
        .then(res => manageResponse(res, 201))
        .then(h => success(h))
        .catch(e => error(e.message));
}

export const getHierarchies = (success, error) => {
    callApi('GET', '/hierarchies')
        .then(res => manageResponse(res, 200))
        .then(h => success(h))
        .catch(e => error(e.message));
}

export const updateNode = (node, success, error) => {
    callApi('PUT', '/navigation/'+ node.nodeId, node)
        .then(res => manageResponse(res, 200))
        .then(n => success(n))
        .catch(e => error(e.message));
}

export const toggleNodeConfidentiality = (node, success, error) => {
    callApi('PUT', '/navigation/'+ node.nodeId + '/toggle-confidentiality', node)
        .then(res => manageResponse(res, 200))
        .then(n => success(n))
        .catch(e => {
            console.log(e);
            error(e.message);
        });
}

export const deleteNode = (nodeId, success, error) => {
    callApi('DELETE', '/navigation/'+ nodeId)
        .then(res => manageDeletionResponse(res, 204))
        .then(p => success())
        .catch(e => error(e.message));
}

export const getNode = (nodeId, success, error) => {
    callApi('GET', `/navigation/${nodeId}?depth=1`)
        .then(res => manageResponse(res, 200))
        .then(p => success(p))
        .catch(e => error(e.message));
}

export const getProductNode = (nodeId, success, error) => {
    callApi('GET', `/navigation/${nodeId}/products`)
        .then(res => manageResponse(res, 200))
        .then(products => {
            let promises = []
            products.map(p => promises.push(callApi("GET", p.links.filter(l => l.rel === "self")[0].href)))
            return Promise.all(promises)
        })
        .then(result => Promise.all(result.map(v => v.json())))
        .then(p => success(p))
        .catch(e => error(e.message));
}

export const updateProductNode = (nodeId, products, success, error) => {
    callApi('PUT', `/navigation/${nodeId}/products`, products)
        .then(res => manageResponse(res, 200))
        .then(products => {
            let promises = []
            products.map(p => promises.push(callApi("GET", p.links.filter(l => l.rel === "self")[0].href)))
            return Promise.all(promises)
        })
        .then(result => Promise.all(result.map(v => v.json())))
        .then(p => success(p))
        .catch(e => error(e.message))
}

export const createNode = (node, success, error) => {
    callApi('POST', '/navigation', node)
        .then(res => manageResponse(res, 201))
        .then(h => success(h))
        .catch(e => error(e.message));
}

export const deletePath = (id, data, success, error) => {
    callApi('DELETE', `/navigation/${id}/ancestors`, data)
        .then(res => manageResponse(res, 200))
        .then(n => success(n))
        .catch(e => error(e.message))
}

export const addPath = (id, data, success, error) => {
    callApi('PUT', `/navigation/${id}/ancestors`, data)
        .then(res => manageResponse(res, 200))
        .then(n => success(n))
        .catch(e => error(e.message))
}

export const reorderChildren = (id, data, success, error) => {
    callApi("PUT", `/navigation/${id}/order`, data)
        .then(res => manageResponse(res, 200))
        .then(n => success(n))
        .catch(e => error(e.message))
}

export const getAllNavigationNodes = (node, success, error) => {
    callApi('GET', node.links.filter(l => l.rel === 'all-navigation-nodes')[0].href)
        .then(res => manageResponse(res, 200))
        .then(n => success(n))
        .catch(e => error(e.message));
}

export const searchNodes = (search, success, error) => {
    callApi('GET', "/")
        .then(r => manageResponse(r, 200))
        .then(r => callApi('GET', r.links.filter(l => l.rel === 'navigation-node-search')[0].href.replace("$search",  encodeURIComponent(search))))
        .then(r => manageResponse(r, 200))
        .then(r => success(r))
        .catch(e => error(e.message))
}

export const updateProduct = (product, success, error) => {
    callApi('PUT', '/products/'+ product.productId, product)
        .then(res => manageResponse(res, 200))
        .then(p => success(p))
        .catch(e => error(e.message));
}

export const deleteProduct = (id, success, error) => {
    callApi('DELETE', '/products/'+ id)
        .then(res => manageDeletionResponse(res, 204))
        .then((p) => success())
        .catch(e => {
            console.log(e);
            error(e.message);
        });
}

export const createProduct = (product, success, error) => {
    callApi('POST', '/products', product)
        .then(res => manageResponse(res, 201))
        .then(p => success(p))
        .catch(e => error(e.message));
}

export const toggleProductConfidentiality = (product, success, error) => {
    callApi('PUT', '/products/'+ product.productId + '/toggle-confidentiality', product)
        .then(res => manageResponse(res, 200))
        .then(p => success(p))
        .catch(e => error(e.message));
}

export const searchProduct = (search, success, error) => {
    callApi('GET', "/")
        .then(res => manageResponse(res, 200))
        .then(r => callApi('GET', r.links.filter(l => l.rel === 'product-search')[0].href.replace("$search",  encodeURIComponent(search))))
        .then(res => manageResponse(res, 200))
        .then(r => success(r))
        .catch(e => error(e.message))
}

function manageResponse(res, expected){
    let json = res.json();
    if (res.status === expected){
        return json;
    }
    return json.then(err => {
        throw new Error(err.statusCode + '(' + err.message + ')');
    });
}

function manageDeletionResponse(res, expected){
    if (res.status === expected) {
        return res
    }
    let json = res.json()
    return json.then(err => {
        throw err;
    });
}

