import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from 'react-bootstrap/InputGroup'
import Button from "react-bootstrap/Button";
import {searchNodes as searchNavNodes} from "../utils/api";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import ListGroup from 'react-bootstrap/ListGroup'

export default function NavigationSearch(props){
    const [search, setSearch] = useState('');
    const [spinner, setSpinner] = useState(false)
    const [results, setResults] = useState(null)
    const [error, setError] = useState('');
    const [valid, setValid] = useState(true);
    const [selected, setSelected] = useState(null);
    const [notFound, setNotFound] = useState(null);

    const responseError = (e) => {
        setError(e.toString())
        clearState();
    }

    const searchNodes = () => {
        if (search !== '') {
            setSpinner(true)
            setNotFound(null)
            searchNavNodes(search,
                (r) => {
                    setResults(r)
                    setSpinner(false)
                    setValid(true)
                    setSearch('')
                    notFoundMessage()
                },
                (e) => responseError(e))
        }else {
            setValid(false)
        }
    }

    const clearState = () => {
        setSearch('')
        setSpinner(false)
        setValid(true)
        setResults(null)
        setSelected(null)
        setNotFound(null)
    }

    const close = () => {
        props.onHide()
        clearState()
    }

    const onSelected = () => {
        props.onSelected(selected)
        props.onHide()
        clearState()
    }

    const notFoundMessage = () => {
        if (results!=null && results.length === 0){
            return setNotFound("Not found any navigation node with that name");
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Search Navigation Nodes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error !== '' &&
                    <Alert variant="danger" onClose={() => setError('')} dismissible>
                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                        <p>{error}</p>
                    </Alert>
                }
                <InputGroup className="mb-3">
                    <FormControl isInvalid={!valid} required type="text" placeholder="Search" className="mr-sm-2" onChange={(event) => setSearch(event.target.value) } />
                    <InputGroup.Append><Button variant="info" onClick={searchNodes}>Search</Button></InputGroup.Append>
                </InputGroup>
                { notFound }
                <ListGroup>
                    {results!=null && results.map(n => selected != null && selected.nodeId === n.nodeId
                        ? <ListGroup.Item key={n.nodeId} action active>
                            {n.labels.filter(l => l.language==="en")[0].name}
                            {n.confidential ? <span className="ml-1"><i className="fa-solid fa-lock"></i></span> : null}
                        </ListGroup.Item>
                        : <ListGroup.Item key={n.nodeId} action onClick={()=>setSelected(n)}>
                            {n.labels.filter(l => l.language==="en")[0].name}
                            {n.confidential ? <span className="ml-1"><i className="fa-solid fa-lock"></i></span> : null}
                        </ListGroup.Item>
                    )}
                </ListGroup>
                {spinner &&
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={selected === null} onClick={onSelected}>Select</Button> <Button onClick={close}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}