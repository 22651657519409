import React from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Container from 'react-bootstrap/Container'
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import {callApi, createHierarchy, createNode, deleteHierarchy, deleteNode, getHierarchies} from "../utils/api";
import Hierarchy from "./hierarchy";
import AlertError from "../utils/alert-error";
import NewSingleValue from "../utils/new-single-value";

export default class HierarchyList extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            selected:null,
            hierarchies: [],
            newHierarchy: false,
            error: ''
        }
    }

    componentDidMount(){
        getHierarchies(
            (h) => this.setState({hierarchies: h}),
            (e) => this.setState({error: e}))
    }

    deleteHierarchy = (id) => {
        console.log("about to delete the hierarchy "+ id);
        deleteHierarchy(id,
            () => {
                console.log('hierarchy deleted ' + id);
                const updatedHierarchies = this.state.hierarchies.filter(h => h.hierarchyId !== id);
                this.setState({
                    hierarchies: updatedHierarchies,
                    selected: null
                });
            },
            (e) => {
                console.log('there has been an error:' + e);
                this.setState({error: e})
            }
        )
    }

    createHierarchy = (name) => {
        let data = {}
        data.labels = [{language: "en", name: name}]
        console.log("about to insert new hierarchy "+ JSON.stringify(data));
        createHierarchy(data,
            (h) => {
                const currentHierarchies = this.state.hierarchies;
                currentHierarchies.push(h);
                this.setState({
                    hierarchies: currentHierarchies,
                    selected: h
                })
            },
            (e) => this.setState({error: e})
        )
    }

    cleanError = () => {
        this.setState({error:''});
    }

    error = (e) => {
        console.log('hierarchy-list:'+e)
        this.setState({error:e})
    }

    render() {
        return [
            (this.state.error !== '' && <AlertError key="error-message" handler={this.cleanError} error={this.state.error} /> ),
            <Container key="hierarchy-actions" fluid mt={4}>
                <Row>
                    <Col sm={2}>
                        <DropdownButton key="ddb-hierarchy" id="dropdown-basic-button" title="Hierarchies">
                            {
                                this.state.hierarchies.map((h,index) =>
                                    <Dropdown.Item key={h.hierarchyId} eventKey={h.hierarchyId} onClick={() => this.setState({selected:h})}>
                                        {h.labels.filter(l => l.language === "en")[0].name}
                                    </Dropdown.Item>
                                )
                            }
                        </DropdownButton>
                    </Col>
                    <Col sm={10}>
                        <Button variant="success" onClick={() => this.setState({newHierarchy: true})}>New Hierarchy</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.state.selected != null ? <Hierarchy hierarchy={this.state.selected} onDelete={this.deleteHierarchy.bind(this)} onError={this.error.bind(this)}/> : <h5>Select a hierarchy from the dropdown.</h5>}
                    </Col>
                </Row>
            </Container>,
            <NewSingleValue key="hierarchy-new" title="New Hierarchy"show={this.state.newHierarchy} onHide={() =>  this.setState({newHierarchy: false})} onSuccess={this.createHierarchy.bind(this)}/>,
        ]
    }
}