import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LabelList from "../labels/label-list";
import Container from "react-bootstrap/Container";
import {deleteProduct, updateProduct, toggleProductConfidentiality} from "../utils/api";
import Card from "react-bootstrap/Card";
import ConfirmModal from "../utils/confirm";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import NewSingleValue from "../utils/new-single-value";
import AlertError from "../utils/alert-error";
import ConfidentialToggle from "../confidential/confidential-toggle";
import FormControl from "react-bootstrap/FormControl";

export default class Product extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            product : props.product,
            confirmDeleteProduct: false,
            confirmDeleteProductAssociation:false,
            productAssociation: null,
            addAssociatedProduct: false,
            error:''
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.product !== this.props.product){
            this.setState({product: this.props.product})
        }
    }

    updateProduct = (product) => {
        let oldProduct = Object.assign({}, this.state.product)
        updateProduct(product,
            (p) => this.setState({ product: p }),
            (e) => this.setState({product: oldProduct, error: e}))
    }

    updateLabel = (labels) => {
        let product = Object.assign({}, this.state.product)
        product.label = labels
        this.updateProduct(product);
    }

    updateConfidential = (confidential) => {
        let product = Object.assign({}, this.state.product);
        product.confidential = confidential;
        toggleProductConfidentiality(product,
            (p) => this.setState( {product: p}),
            (e) => {
                this.props.onError(e)
            })
    }

    deleteProduct = () => {
        deleteProduct(this.state.product.productId,
            () => this.setState({ product: null }),
            (e) => this.setState({error: e}))
    }

    deleteAssociatedProduct = () =>{
        let product = Object.assign({}, this.state.product)
        product.associatedProducts = this.state.product.associatedProducts.filter(a => a !== this.state.productAssociation)
        this.setState({productAssociation:null})
        this.updateProduct(product);
    }

    addAssociatedProduct = (a) => {
        let product = Object.assign({}, this.state.product)
        product.associatedProducts.push(a)
        this.updateProduct(product);
    }

    updateProductName = (name) => {
        let product = Object.assign({}, this.state.product)
        product.name = name
        this.updateProduct(product);
    }

    render() {
        return [
            (this.state.error !== '' && <AlertError key="error-message" handler={() => this.setState({error: ''})} error={this.state.error} />),
            (this.state.product != null ?
                <Card key="product-render" className="mt-1" >
                    <Card.Header>
                        <Container>
                            <Row>
                                <Col sm={9}><h4>Product</h4> <small className="text-muted">{this.state.product.productId}</small></Col>
                                <Col sm={3}><Button variant="danger" onClick={() => this.setState({confirmDeleteProduct: true})}>Delete Product</Button></Col>
                            </Row>
                        </Container>
                    </Card.Header>
                    <Card.Body>
                        <Container>
                            <Row key="Name-header" className="mt-3">
                                <Col><h5>Name</h5></Col>
                            </Row>
                            <Row >
                                <Col>
                                    <FormControl key="input" value={this.state.product.name} disabled={true} />
                                </Col>
                            </Row>
                            <Row key="label-header" className="mt-3">
                                <Col><h5>Labels</h5></Col>
                            </Row>
                            <Row key="label-list">
                                <Col><LabelList labels={this.state.product.label != null ? this.state.product.label : []} onUpdate={this.updateLabel.bind(this)}/></Col>
                            </Row>
                            <Row key="confidential-toggle">
                                <Col><ConfidentialToggle confidential={this.state.product.confidential}
                                                         id={this.state.product.productId}
                                                         onUpdate={this.updateConfidential.bind(this)}
                                                         alertMessage={"All nodes associated to this product will also have their confidentiality flag toggled automatically"}
                                /></Col>
                            </Row>
                            <Row key="product-header" className="mt-3">
                                <Col sm={9}><h5>Product codes associated to this product </h5></Col>
                                <Col sm={3}><Button variant="primary" onClick={() => this.setState({addAssociatedProduct: true})}>Add product code</Button></Col>
                            </Row>
                            <Row key="product-associated" className="mt-3">
                                <Col>
                                    { this.state.product.associatedProducts != null && this.state.product.associatedProducts.length > 0 ?
                                        this.state.product.associatedProducts.map(a =>
                                            <Alert key={a} className="alert-auto" variant="info"
                                                   onClose={() => this.setState({confirmDeleteProductAssociation: true, productAssociation:a})}
                                                   dismissible={a == this.state.product.name ? false : true}>
                                                {a}
                                            </Alert>
                                        )
                                        : "There is no associated products codes"
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card> : null ),
            <ConfirmModal key="confirmation-delete-product" show={this.state.confirmDeleteProduct} onHide={() => this.setState({confirmDeleteProduct: false})} onConfirmation={this.deleteProduct.bind(this)} />,
            <ConfirmModal key="confirmation-delete-product-association" show={this.state.confirmDeleteProductAssociation} onHide={() => this.setState({confirmDeleteProductAssociation: false})} onConfirmation={this.deleteAssociatedProduct.bind(this)} />,
            <NewSingleValue key="add-product-association" title="Product code to associate" show={this.state.addAssociatedProduct} onHide={() => this.setState({addAssociatedProduct: false})} onSuccess={this.addAssociatedProduct.bind(this)} />
        ]
    }
}