import React from "react";
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {getNode} from "../utils/api";
import Spinner from "react-bootstrap/Spinner";


export default class HierarchyPath extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            paths: props.paths,
            nodes: [],
            spinner: false
        }
    }

    componentDidMount() {
        this.fetchNodes()
    }

    componentDidUpdate(prevProps){
        if (prevProps.paths !== this.props.paths){
            this.setState({paths: this.props.paths, nodes:[]}, this.fetchNodes) // callback to get fetch the nodes
        }
    }

    fetchNodes = () => {
        this.setState({spinner:true})
        const distinctNodes = [...new Set(this.state.paths.flatMap(p => p))]
        let promises = []
        distinctNodes.map(p => promises.push(new Promise(resolve => getNode(p, (n) => resolve(n), (e) => reject(e)))))
        Promise.all(promises)
        .then((values) => {
            this.setState({nodes: values, spinner:false})
        });
    }

    calculateNames = () => {
        let result = []
        this.state.paths.map((p,idx) => {
            let hierarchy = ""
            p.map((pp) => {
                    const n = this.state.nodes.filter(n1 => n1.nodeId === pp)[0]
                    hierarchy += n.labels.filter(x => x.language === "en")[0].name
                    n.hierarchy ? hierarchy += " [Hierarchy]" :  hierarchy += " [Node]"
                    if (p.indexOf(pp) !== p.length-1) hierarchy += " -> "
            })
            result.push(<Alert key={idx}  variant='success'>{hierarchy}</Alert>);
        })
        return result;
    }

    render() {
        return (
            <Row>
                <Col>
                    {this.state.spinner &&
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    }
                    {this.state.nodes.length === 0
                        ? <p>No hierarchies</p>
                        : this.state.nodes.length > 0 && this.calculateNames()
                    }
                </Col>
            </Row>
        )
    }
}
