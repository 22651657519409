import React from "react";
import ConfirmModal from "../utils/confirm";

export default class ConfidentialToggle extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            confidential: props.confidential,
            confirmToggleConfidential: false,
            id: props.id
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.confidential !== this.props.confidential){
            this.setState({confidential: this.props.confidential})
        }
    }

    toggleChange = () => {
        let newConfValue = !this.state.confidential
        this.setState(({confidential: newConfValue}))
        this.props.onUpdate(newConfValue)
    }

    render () {
        return[
            <form className="mb-4" key={this.state.id + this.props.confidential}>
                <div className="custom-control custom-switch">
                    <input type="checkbox" id={this.state.id}
                           className="custom-control-input"
                           onChange={() => this.setState({confirmToggleConfidential: true})}
                           checked={this.props.confidential}
                           disabled={!this.props.confidential}
                    />
                    <label title="Confidentiality" htmlFor={this.state.id} className="custom-control-label">{this.props.confidential ? "Confidential" : "Non-Confidential"}</label>
                    {this.props.confidential ? <span className="ml-1"><i className="fa-solid fa-lock"></i></span> : null}
                </div>
            </form>,
            <ConfirmModal key="confirmation-toggle-confidential" show={this.state.confirmToggleConfidential}
                          onHide={() => this.setState({confirmToggleConfidential: false})}
                          onConfirmation={this.toggleChange.bind(this)}
                          alertMessage={this.props.alertMessage} />
        ]
    }
}