import Card from "react-bootstrap/Card";
import React from "react";
import {addPath, callApi, getAllNavigationNodes, reorderChildren, updateNode} from "../utils/api";
import NavigationHierarchyItem from "./navigation-hierarchy-item";
import {Button} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner'
import NavigationSearch from "./navigation-search";
import AlertError from "../utils/alert-error";
import {orderNodes} from "../utils/common-functions";

export default class NavigationHierarchy extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            hierarchy : props.hierarchy,
            nodes: [],
            spinner:false,
            search:false,
            error:''
        }
    }

    componentDidMount(){
        this.fetchNodes(this.state.hierarchy);
    }

    componentDidUpdate(prevProps) {
        if (this.props.hierarchy !== prevProps.hierarchy){
            this.setState({
                hierarchy: this.props.hierarchy,
                nodes: []
            });
            console.log('update component navigation list: '+this.props.hierarchy)
            this.fetchNodes(this.props.hierarchy);
        }
    }

    fetchNodes = (hierarchy) => {
        this.setState({spinner:true})
        getAllNavigationNodes(hierarchy, (n) => this.setState({nodes: n, spinner:false}))
    }

    addNode = (node) => {
        this.setState({spinner:true})
        let res = {}
        res.ancestors = [this.state.hierarchy.hierarchyId]
        addPath(node.nodeId, res,
            (n) => this.fetchNodes(this.state.hierarchy),
            (e)=> this.setState({search:false, spinner:false, error: e }))
    }

    cleanError = () => {
        this.setState({error:''})
    }

    reorderChildren = (node, offset) => {
        this.setState({spinner:true})
        let ordered = orderNodes(node, offset, this.state.nodes)
        let data = {}
        data.navigationNodeOrdered = ordered.map(n => n.nodeId)
        reorderChildren(this.state.hierarchy.hierarchyId, data,
            (n) => this.setState({nodes: ordered, spinner: false}),
            (e) => this.setState({error:e, spinner: false}))
    }


    render() {
        if (this.state.hierarchy != null){
            return [
                <Card key="navigation-hierarchy" className="mt-1" >
                    <Card.Header>Navigation Nodes for the hierarchy : {this.state.hierarchy.hierarchyId}</Card.Header>
                    <Card.Title><Button key="add-node" variant="success" onClick={() => this.setState({search:true})}>Add Node</Button></Card.Title>
                    <Card.Body>
                        {this.state.error !== '' && <AlertError key="error-message" handler={this.cleanError} error={this.state.error} /> }
                        {this.state.spinner &&
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        }
                        <Card.Body >
                            { this.state.nodes.map(n => <NavigationHierarchyItem key={n.nodeId} node={n} ancestors={[this.state.hierarchy.hierarchyId]} onReorder={this.reorderChildren.bind(this)} onRefresh={() => this.fetchNodes(this.state.hierarchy) } onError={(e) => this.setState({error:e})}/> )}
                        </Card.Body>
                    </Card.Body>
                </Card>,
                <NavigationSearch key="navigation-search" show={this.state.search} onHide={() =>  this.setState({search: false})} onSelected={this.addNode.bind(this)} />
            ];
        }
        return null;
    }
}