import React, {useState} from "react";
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


export default function NavigationPath(props){
    const [show, setShow] = useState(false)

    if (show) {
        return (
            <Container>
                <Row>
                    <Col>
                    {props.path.map((p,idx) =>
                        <Alert key={idx}  variant='info'>{p}</Alert>
                    )}
                    </Col>
                    <Col>
                        <Button variant="warning" className="alert-auto" onClick={() => setShow(false)}>Hide</Button>
                    </Col>
                </Row>
            </Container>
        )
    }else{
        return (<Button variant="warning" onClick={() => setShow(true)}>Show Navigation Path</Button>)
    }

}