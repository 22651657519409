import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from './security/auth-provider';
import {basicReduxStore}  from './security/redux-store';
import App from './app'
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
    <AzureAD provider={authProvider} forceLogin={true} reduxStore={basicReduxStore}>
        {
            ({login, logout, authenticationState, error, accountInfo}) => {
                switch (authenticationState) {
                    case AuthenticationState.Authenticated:
                        return (<BrowserRouter><App name={accountInfo.account.name}/></BrowserRouter>);
                    case AuthenticationState.Unauthenticated:
                        return (<div>{error && <p><span>An error occured during authentication, please try again!</span></p>}</div>);
                    case AuthenticationState.InProgress:
                        return (<p>Authenticating...</p>);
                }
            }
        }
    </AzureAD>,
    document.getElementById("root")
);