import Label from "./label";
import Table from "react-bootstrap/Table";
import React from "react";
import Button from "react-bootstrap/Button";
import LabelNew from "./label-new";

export default class LabelList extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            labels: props.labels,
            newlabel: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.labels !== this.props.labels){
            this.setState({labels: this.props.labels})
        }
    }

    deleteLabel = (label) => {
        let hierarchywithoutLabel = this.state.labels.filter(l => l.language !== label.language);
        this.props.onUpdate(hierarchywithoutLabel);
    }

    addLabel = (label) => {
        let newlabels = Object.assign([], this.state.labels)
        newlabels.push(label)
        this.props.onUpdate(newlabels)
    }

    updateLabel = (label, newtext) => {
        let labels = Object.assign([], this.state.labels)
        labels.filter(l => l.language === label.language)[0].name = newtext
        this.props.onUpdate(labels)
    }

    render() {
        return [
            <Table key="label-table">
            <thead>
            <tr>
                <th>Language</th>
                <th>Name</th>
                <th><Button variant="success" mt={2} onClick={() => this.setState({newlabel: true})}>Add label</Button></th>
            </tr>
            </thead>
            <tbody>
            {
                this.state.labels.map((label, index) =>
                    <Label key={index} label={label} onDelete={this.deleteLabel.bind(this)} onUpdate={this.updateLabel.bind(this)} />
                )
            }
            </tbody>
        </Table>,
        <LabelNew key="label-new" show={this.state.newlabel} onHide={() =>  this.setState({newlabel: false})} onSuccess={this.addLabel.bind(this)}/>
        ]
    }
}