import React from "react";
import Product from "./product";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ProductSearch from "./product-search";
import NewSingleValue from "../utils/new-single-value";
import {callApi, createProduct} from "../utils/api";
import AlertError from "../utils/alert-error";

export default class ProductList extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            search:false,
            newProduct:false,
            product:null,
            error: ''
        }
    }

    createProduct = (name) =>{
        let data = {}
        data.name = name
        data.confidential = true
        createProduct(data,
            (p) =>  this.setState({product: p}),
            (e) => this.setState({error: e}))
    }


    render() {
        return [
            (this.state.error !== '' && <AlertError key="error-message" handler={this.cleanError} error={this.state.error} />),
            <Container key="product-details">
                <Row>
                    <Col><Button variant="primary" onClick={() => this.setState({search: true})}>Search Products</Button></Col>
                    <Col><Button variant="success" onClick={() => this.setState({newProduct:true})}>New Product</Button></Col>
                </Row>
                <Row>
                    <Col>{this.state.product !== null ? <Product product={this.state.product} onError={(e) => this.setState({error:e})}/> : null }</Col>
                </Row>
            </Container>,
            <ProductSearch key="product-search" show={this.state.search} onHide={() =>  this.setState({search: false})} onSelected={(p) =>  this.setState({product:p})} />,
            <NewSingleValue key="product-new" title="New Product" show={this.state.newProduct} onHide={() =>  this.setState({newProduct: false})} onSuccess={this.createProduct.bind(this)}/>
            ];
    }
}