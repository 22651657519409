import React, {useState} from "react";
import Alert from "react-bootstrap/Alert";

export default function AlertError(props) {
    const [show, setShow] = useState(true);

    const close = () => {
        props.handler();
        setShow(false);
    }

    if (show) {
        return (
            <Alert variant="danger" onClose={close} dismissible>
                <Alert.Heading>You got an error!</Alert.Heading>
                <p>{props.error}</p>
            </Alert>
        );
    }
    return null;
}